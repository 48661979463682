<template>
    <div class="main-container">
        <Header />
        <InfoContato />
        <Footer />
        <!-- <OffCanvasMobileMenu /> -->

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>

    import Header from '@/components/Header';
    import InfoContato from '../components/sections/InfoContato'
    import Footer from '../components/Footer'
    // import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';

    export default {
        components: {
            Header,
            InfoContato,
            Footer,
            //OffCanvasMobileMenu
        },
        metaInfo: {
            title: 'Vix Inpeções',
            titleTemplate: `%s - contato`,
            meta: [{
            name: 'description',
            content: 'Vix Inpeções'
            },
            {
            name: 'keywords',
            content: 'Vix Inspeções, inspenções, vistoria, vistorias de veículos escolares, veículos pesados, veículos de passeios'
            }
            ],
            htmlAttrs: {
                lang: 'pt-BR',
                amp: true
            }
        }
    }
</script>

