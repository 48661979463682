<template>
    <div class="">
        <div class="secoes_site">     
            <div class="">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="breadcrumb">
                                <h1>Fale Conosco</h1>
                                <router-link to="/" class='alinkhome'>Home</router-link>  <span class="spancontato">/  Fale Conosco</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Contact section start-->
        <section class="contact-section">
            <div class="container">
                <div class="row">
                    <div v-if="error != undefined ">
                                <div class="alert alert-warning alert-dismissible fade show">
                                    <p>{{error}}</p>
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div><br>
                                
                            </div>
                            <div v-if="success != undefined ">
                                <div class="alert alert-success alert-dismissible fade show">
                                    <p>{{success}}</p>
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                </div><br>
                                
                            </div>
                            <p class="form-message"></p>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h2 class="contact-title">Envie uma mensagem</h2>
                    </div>
                    <div class="col-lg-8">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea class="form-control w-100" name="mensagem" v-model="mensagem" cols="30" rows="9" placeholder="Mensagem"></textarea>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                         <input name="nome" class="form-control valid" type="text" v-model="nome" placeholder="Nome">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                         <input name="email" type="email" class="form-control valid" placeholder="E-mail" v-model="email">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                     <input name="telefone" type="text" class="form-control" placeholder="Telefone" v-model="telefone">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                         <input name="assunto" type="text" class="form-control" placeholder="Assunto" v-model="assunto">                                        
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-3">
                                <button class="button button-contactForm boxed-btn" @click="register">ENVIAR</button>
                            </div>
                        
                    </div>
                    <div class="col-lg-3 offset-lg-1">
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-home"></i></span>
                            <div class="media-body">
                                <h3> {{infocontato.endereco}}</h3>
                                <p>Endereço</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-tablet"></i></span>
                            <div class="media-body">
                                <h3>{{ infocontato.telefone}}</h3>
                                <p>Telefone</p>
                            </div>
                        </div>
                        <div class="media contact-info">
                            <span class="contact-info__icon"><i class="ti-email"></i></span>
                            <div class="media-body">
                                <h3>{{ infocontato.email}}</h3>
                                <p>Email</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--Contact section end-->
    </div>
</template>

<script>
import axios from 'axios';
import Breadcrumb from '../Breadcrumb.vue';
    export default {
  components: { Breadcrumb },
        data () {
            return {
                nome: '',
                email: '',
                telefone: '',
                assunto: '',
                mensagem: '',
                error: undefined,
                success: undefined,
                infocontato: [],
            }
        },
        created(){
        axios.get("https://sistema.gouarts.com/api/home?cliente=vitoriainspecoes").then(res => {
             this.infocontato = res.data.configuracoes;
             
        }).catch(err => {
           console.log(err);
           
        })
      },
        methods: {
        register(){
            axios.post("https://sistema.gouarts.com/api/contato/enviar?cliente=vitoriainspecoes",{
                nome: this.nome,
                email: this.email,
                telefone: this.telefone,
                assunto: this.assunto,
                mensagem: this.mensagem
            }).then(res => {
                var msgErro = res.data.err;
                this.success = msgErro;
                this.error = undefined;
            }).catch(err => {
                var msgErro = err.response.data.err;
                this.error = msgErro;
                this.success = undefined;
            })
        },
        
    },
    
};
</script>
<style scoped>
.slider-area {
    height:250px;
}
.alinkhome {
    margin-top:13px; margin-left:15px
}

.spancontato{
    margin-top:13px; margin-left:15px
}
</style>